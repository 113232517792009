.App {
  text-align: center;
  color: black;
  font-family: 'Kalam', cursive;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ccaaaa; /* Adjust background color as needed */
}

.login-page {
  background-image: url('../../public/backgroudn.webp');
  background-position: center;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  /* flex-direction: column; */
}

.title {
  font-size: 84px;
  margin-top: 100px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@font-face {
  font-family: 'Kalam';
  src: local('Kalam'), url(../fonts/Kalam/Kalam-Bold.ttf) format('truetype');
  font-weight: bold; /* or 400 for normal, depending on your font file */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.google-sign-in-btn {
  background-color: #72a4f7; /* Google's brand blue color */
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between logo and text */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  margin-right: 10px;
}

.google-sign-in-btn:hover {
  box-shadow: 0 0 6px #aaaaaa;
  background-color: #1266f1;
}

.google-sign-in-btn:active {
  background-color: #aaaaaa; /* Slightly darker blue on hover */
  box-shadow: 0 0 0;
}

.google-sign-in-btn img {
  height: 20px; /* Adjust size of the Google logo */
  width: 20px;
}

.google-sign-in-btn span {
  color: black;
  font-weight: 500;
}

.guest-btn {
  background-color: gray; /* Google's brand blue color */
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Space between logo and text */
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  margin-left: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.guest-btn:hover {
  box-shadow: 0 0 6px #aaaaaa;
  background-color: white;
}

.guest-btn:active {
  background-color: #aaaaaa; /* Slightly darker blue on hover */
  box-shadow: 0 0 0;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  font-family: 'Lato', sans-serif;
  align-items: center;
}

.login-description {
  font-size: 30px;
  margin-bottom: 100px;
  margin-left: 20px;
  margin-right: 20px;
}

.signInText {
  color: white;
}

.terms-privacy {
  font-size: 14px;
}

.blurb-container {
  font-size: 20px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blurb {
  width: 70%;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding inside the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  align-self: center;
}

a.nav-link:link
{
   color: gray;
   text-decoration: none;
}
a.nav-link:visited
{
  color: gray;
  text-decoration: none;
}
a.nav-link:hover
{
  color: gray;
  text-decoration: none;
}
a.nav-link:active
{
  color: gray;
  text-decoration: none;
}


@media (max-width: 768px) {
  .title {
    font-size: 64px; /* Smaller font size for mobile */
    margin: 30px;
  }

  .login-page {
    flex-direction: column; /* Ensure vertical stacking for better readability */
  }

  .google-sign-in-btn {
    font-size: 14px; /* Smaller font size for the button text */
    padding: 8px 16px; /* Smaller padding */
    gap: 5px; /* Reduce space between logo and text */
  }

  .google-sign-in-btn img {
    height: 15px; /* Smaller Google logo */
    width: 15px;
  }

  .description {
    font-size: 18px; /* Smaller description text */
    margin-bottom: 50px; /* Less margin to save space */
    margin-left: 40px;
    margin-right: 40px;
  }

  .terms {
    font-size: 12px; /* Smaller font size for terms of service text */
    margin: 30px;
  }

  .blurb {
    font-size: 16px; /* Smaller font size for the blurb */
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
  }
}
