.user-info {
  margin-bottom: 20px;
}

.user-reviews p {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  /* margin: 10px 0; */
  margin-left: 90px;
  margin-right: 90px;
}

.user-reviews h2 {
  font-family: Poppins;
  font-weight: 500;
}

.content {
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 40px;
}

.userpage {
  width: 100vw;
  min-height: 100vh;
}

.user-all {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* width: 100%; */
  background-color: #eeeeee;

}

.user-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
}

.user-details,
.user-stats {
  flex: 1;
}

.profile-and-name {
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 60px;
  font-weight: 600;
  /* margin-right: 50px; */
  font-family: Poppins;
}

.user-subtitle {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.bio {
  font-size: 15px;
  margin-bottom: 20px;
}

.edit-bio-button {
  /* Style similarly to the review button */
}

/* Adjust or add styles for .user-stats to include the ratings distribution graph */

.ratings-distribution {
  margin-bottom: 20px;
}

/* Container for the channel content below the top bar */
.user-content {
  display: flex;
  justify-content: space-between;
  /* margin-top: 20px;
  padding: 20px; */
}

/* Left column for channel details */
.user-details {
  flex: 1;
  /* Take up half of the container */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Align items to the start of the flex-direction */
}

.user-details .user-name {
  font-size: 60px;
  /* Big text for channel name */
  margin-right: 50px;
  text-align: left;
  max-width: 500px;
}

.user-details .user-subtitle {
  font-size: 18px;
  /* Subtitle text size */
  color: #666;
  /* Subtle color for subtitle */
  margin-bottom: 20px;
  /* Space between subtitle and profile picture */
  font-family: Lato;
  font-style: italic;
}

.user-details .profile-and-name {
  display: flex;
  align-items: center;
}

.user-details img {
  width: 100px;
  /* Adjust based on your preference */
  height: 100px;
  /* Adjust to keep aspect ratio */
  border-radius: 50%;
  /* Circle shape */
  margin-right: 20px;
  /* Space between picture and description */
}

.user-details .description {
  flex: 1;
  font-size: 15px;
  font-family: Lato;
  text-align: left;
}

/* Right column for stats */
.user-stats {
  flex: 1;
  /* Take up half of the container */
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 100px;
  font-family: Lato;
}

.bio {
  font-family: Lato;
}

.edit-bio-textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Lato;
}

.submit-bio-button {
  width: 100%;
  padding: 10px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-bio-button:hover {
  background-color: #357ae8;
}


.follow-stats {
  margin-bottom: 10px;
}

.liked-videos-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.liked-video-item {
  background-color: #dddddd;
  display: flex;
  gap: 15px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.liked-video-item:hover {
  background-color: #f8f8f8;
}

.video-thumbnail img {
  object-fit: cover;
  border-radius: 4px;
  margin-right: 20px;
}

.video-details {
  flex: 1;
}

.liked-video-title {
  font-family: Lato;
  margin: 0 20px 5px 0;
  font-size: 20px;
  font-weight: normal;
  text-align: left;
}

.liked-video-title a {
  color: #0f0f0f;
  text-decoration: none;
}

.liked-video-title a:hover {
  text-decoration: underline;
}

.channel-title {
  margin: 10px 20px 5px 0;
  color: #606060;
  font-family: Lato;
  font-size: 16px;
  text-align: left;
}

.channel-title a {
  color: #606060;
  text-decoration: none;
}

.channel-title a:hover {
  color: #0f0f0f;
}

.video-date {
  margin: 0;
  color: #606060;
  font-family: Lato;
  font-size: 12px;
}

.user-button {
  max-width: 300px;
}

@media (max-width: 768px) {
  .user-all {
    padding: 20px;
    /* Reduced padding for smaller screens */
  }

  .user-details,
  .user-stats {
    flex-direction: column;
    /* align-items: center; Center alignment for better visual balance */
  }

  .user-details .user-name,
  .user-details .user-subtitle {
    font-size: 24px;
    /* Smaller font size for space efficiency */
    text-align: center;
    /* Center text for consistency */
    /* margin-right: f10; Remove right margin */
  }

  .user-details img {
    width: 50px;
    /* Smaller image size */
    height: 50px;
  }

  .user-content {
    flex-direction: column;
    /* Stack user details and stats vertically */
    width: 90%;
    margin-top: 50px;
  }

  .edit-bio-textarea,
  .submit-bio-button {
    width: 100%;
    /* Full width buttons and inputs for better accessibility */
    padding: 8px;
    /* Slightly less padding to fit smaller screens */
  }

  .bio,
  .user-details .description {
    font-size: 14px;
    /* Smaller font for descriptions and bios */
  }

  .user-stats {
    margin-left: 10px;
  }

  .liked-video-item {
    padding: 10px;
    gap: 10px;
    margin-bottom: 8px;
  }

  .liked-video-thumbnail img {
    width: 90px;  /* Smaller thumbnail */
    height: 68px;
    margin-right: 10px;
  }

  .liked-video-title {
    font-size: 16px;  /* Smaller title */
    margin-bottom: 3px;
  }

  .channel-title {
    font-size: 11px;
    margin: 3px 0;
  }

  .video-date {
    font-size: 10px;
  }

  .user-reviews p {
    margin-left: 20px;  /* Smaller margins */
    margin-right: 20px;
  }

  .load-more-button {
    padding: 8px 16px;
    font-size: 12px;
  }
}
