.video-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 300px;
  background-color: #eeeeee;
  min-width: 100vw;
  min-height: 100vh;
}

.page-body {
  /* max-width: 1500px; */
  width: 100%;
  background-color: #eeeeee;
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
}

.video-embed {
  margin-bottom: 20px;
}

.video-embed iframe {
  height: 400px;
  width: 600px;
}

.analysis-result, .transcript-result {
  max-width: 80%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 80%;
  text-align: left;
  font-family: Poppins;
}                 

.summary {
  font-family: Lato;
}

.comp-questions {
  font-family: Lato;
}

.answer-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.other-button {
  margin-right: 30px;
  margin-top: 30px;
}

.top-part {
  display: flex;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 20px;
}

.video-info {
  margin-left: 50px;
  text-align: left;
}

.video-title {
  font-size: 30px;
  font-family: Poppins;
}

.video-channel {
  font-size: 20px;
  font-family: 'Lato';
  margin-bottom: 30px;
}

.question-row {
  display: flex;
  align-items: center;
}

.rate-button {
  margin-top: 30px;
}

.ratings-distribution {
  font-family: Lato;
}

.video-reviews-section {
  width: 80%;
}

.problems-report {
  font-family: Lato;
}

a.nav-link:link
{
   color: gray;
   text-decoration: none;
}
a.nav-link:visited
{
   color: gray;
   text-decoration: none;
}
a.nav-link:hover
{
   color: gray;
   text-decoration: none;
}
a.nav-link:active
{
   color: gray;
   text-decoration: none;
}

a.nav-link:link
{
   color: gray;
   text-decoration: none;
}
a.nav-link:visited
{
   color: gray;
   text-decoration: none;
}
a.nav-link:hover
{
   color: lightgray;
   text-decoration: none;
}
a.nav-link:active
{
   color: gray;
   text-decoration: none;
}



.num-reviews {
  font-family: Poppins;
}

@media (max-width: 840px) {
  .video-page {
    margin-top: 200px; /* Adjust margin-top for mobile */
  }

  .video-embed iframe {
    width: 100%;
    height: 100%;
  }

  .top-part {
    flex-direction: column; /* Stack video and info vertically */
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .video-embed {
    max-width: 90%; /* Remove max-width constraint */
    aspect-ratio: 3 / 2; /* Maintain 3:2 aspect ratio */
  }

  .video-info {
    margin-left: 0;
    margin-top: 0px;
    width: 95%;
    text-align: center;
  }

  .video-title {
    font-size: 20px; /* Adjust font size for mobile */
  }

  .video-channel {
    font-size: 16px; /* Adjust font size for mobile */
  }

  .ratings-distribution {
    width: 100%;
    margin: 0 auto;
  }

  .question-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .answer-button {
    margin-left: 0;
    margin-top: 10px;
  }

  .other-button {
    margin-right: 5px;
    margin-top: 10px;
  }

  .video-reviews-section {
    width: 90%;
  }
}

.analyze-section {
  font-family: Lato;
}