/* Homepage.css */

.climbing-guide {
  /* min-height: 100vh; */
  background-color: #eeeeee;
  width: 100%;
  height: 100vh;
  padding-top: 300px;
  /* overflow: scroll; */
}

body {
  background-color: #eeeeee;
}

.description {
  font-family: Lato;
  font-size: 20px;
}

.guide-title {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Poppins;
  font-weight: 500;
}

h1 {
  font-family: Poppins;
  font-weight: 500;
}

img {
  border-radius: 10px;
}