.topic-page-container {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  align-items: center;
  margin-top: 100px;
  font-family: Lato;
  /* font-size: 20px; */
}

.topic-title {
  font-size: 48px;
  font-family: Poppins;
  /* color: #333; */
  margin-bottom: 0px;
}

.topic-description {
  font-size: 18px;
  /* color: #666; */
  margin-bottom: 20px;
  max-width: 80%;
}

.channel-container {
  border-bottom: 1px solid #ccc;
  /* padding-bottom: 20px; */
  margin-bottom: 10px;
  margin-top: 10px;
  justify-content: center;
  max-width: 1500px;
  min-width: 50%;
  width: 85%;
}

.channel-name {
  font-size: 30px;
  /* color: #444; */
  cursor: pointer;
  text-align: left;
}

.topic-channel-description {
  font-size: 16px;
  /* color: #555; */
  text-align: left;
  margin-left: 40px;
}

.content-container {
  display: flex;
  align-items: center;
  width: 100%;
  /* justify-content: center; */
}

.vote-controls {
  flex-shrink: 0;
  text-align: center;
  margin-right: 20px;
  align-self: center;
  margin-left: 20px;
  margin-bottom: 15px;
}

.vote-button {
  display: block;
  font-size: 24px;
  cursor: pointer;
}

.vote-button:hover {
  color: gray;
}

.score {
  font-size: 18px;
  margin: 20px 0;
}

.topic-video-list {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.video-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.video-thumbnail {
  /* width: 100%; */
  max-width: 200px;
  height: auto;
  /* border-radius: 4px; */
}

.topic-channel-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.topic-channel-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  margin-left: 40px;
  margin-bottom: 10px;
  /* width: 100%; */
}

.topic-channel-info:hover {
  color: gray;
}

.all-channel-stuff {
  justify-content: center;
  /* width: 100%; */
}

.toggle-view-button {
  margin-bottom: 10px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  text-align: center;
  font-weight: 400;
}

.popup-inner button {
  margin-left: 10px;
  margin-right: 10px;
}

.popup-inner-text {
  margin-bottom: 20px;
}


@media (max-width: 768px) {
  .topic-page-container {
    padding: 0px; /* Smaller padding for smaller screens */
    margin-top: 80px; /* Reduced top margin to account for a smaller fixed top bar */
  }

  .topic-title {
    font-size: 20px;
  }

  .topic-description {
    font-size: 16px;
  }

  .channel-name {
    font-size: 18px;
    font-weight: 500;
    word-wrap: break-word;
  }

  .channel-container {
    min-width: 90%;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .topic-video-list {
    flex-direction: column;
  }

  .vote-controls {
    margin: 5px;
    margin-bottom: 15px;
  }

  .vote-button {
    font-size: 16px;
  }

  .score {
    font-size: 16px;
  }

  .topic-channel-info {
    margin-left: 20px;
  }
}