.userList {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  align-items: center;
  margin-top: 100px;
}

.userList h1 {
  margin-bottom: 50px;
}

.user-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 5px;
  width: 600px;
  /* padding: 20px 0; */
}

.user-list-item {
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
  font-family: Lato;
  text-align: left;
}

.user-list-item:hover {
  background-color: #dddddd;
}

.user-list-item img {
  width: 80px;
  height: 80px;
  border-radius: 30%;
  object-fit: cover;
}

.user-list-item span {
  font-size: 20px;
}

@media (max-width: 768px) {
  .userList {
    padding: 0px;
    margin-bottom: 0px;
  }

  .userList h1 {
    margin-bottom: 10px; /* Reduce the bottom margin for the header */
    margin-top: 0px;
  }

  .user-list-grid {
    width: 90%; /* Allow the grid to fill the available width */
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr)); /* Adjust column size */
  }

  .user-list-item {
    gap: 10px; /* Reduce the gap between elements */
    padding: 10px; /* Reduce padding */
    /* max-width: 150px; */
  }

  .user-list-item img {
    width: 50px; /* Reduce image size */
    height: 50px;

  }

  .user-list-item span {
    font-size: 16px; /* Adjust font size */
  }
}
