/* Page Layout */
.wrapped-page {
    width: 100vw;
    min-height: 100vh;
    background-color: #eeeeee;
}

.wrapped-content {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Header Styles */
.wrapped-content h1 {
    font-family: Poppins;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 20px;
}

.description {
    font-family: Lato;
    font-size: 18px;
    color: #606060;
    max-width: 600px;
    line-height: 1.6;
    margin-bottom: 40px;
}

/* Button Styles */
.wrapped-button {
    display: flex;
    align-items: center;
    gap: 10px;
    /* background-color: #346fd3; */
    /* color: white; */
    border: none;
    border-radius: 4px;
    padding: 15px 30px;
    font-family: Lato;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin: 0;
}

.wrapped-button .google-icon {
    width: 20px;
    height: 20px;
}

/* Results Container */
.wrapped-results {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

/* Stats Section */
.wrapped-stat {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.stat-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.wrapped-stat-item {
    display: flex;
    align-items: center;
    gap: 15px;
}

.wrapped-stat-item h2 {
    font-family: Poppins;
    font-size: 18px;
    margin: 0;
    white-space: nowrap;
}

.wrapped-stat-item .stat-number {
    font-family: Poppins;
    font-size: 36px;
    color: #6DD3CE;
    font-weight: 600;
    margin: 0;
}

.stat-divider {
    width: 1px;
    height: 30px;
    background-color: #ddd;
}

/* Channels Section */
.wrapped-channels {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
}

.wrapped-channels h2 {
    font-family: Poppins;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.wrapped-channel-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.wrapped-channel-item {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    gap: 15px;
}

.wrapped-channel-item:hover {
    background-color: #f0f0f0;
}

.wrapped-channel-item.expanded {
    background-color: #e8e8e8;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* Channel Item Components */
.channel-rank {
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
    color: #6DD3CE;
    min-width: 30px;
}

.channel-name {
    font-family: Lato;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.channel-count {
    font-family: Lato;
    color: #606060;
    min-width: fit-content;
    margin-left: auto;
    padding-right: 10px;
}

.expand-icon {
    color: #606060;
    font-size: 12px;
    min-width: 12px;
}

/* Channel Videos Section */
.channel-videos {
    background-color: white;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
}

.channel-video-item {
    margin-bottom: 10px;
}

.channel-video-item:last-child {
    margin-bottom: 0;
}

/* Video Link Styles */
.video-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.video-link:hover {
    background-color: #f8f8f8;
}

.video-thumbnail {
    width: 120px;
    height: 68px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 15px;
}

.list-video-info {
    flex: 1;
}

.list-video-title {
    font-family: Lato;
    font-size: 14px;
    margin-bottom: 5px;
    color: #0f0f0f;
    text-align: left;
}

.list-video-date {
    font-family: Lato;
    font-size: 12px;
    color: #606060;
    text-align: left;
}

/* Loading States */
.loading-spinner {
    font-family: Lato;
    color: #606060;
    margin: 20px 0;
    text-align: center;
}

.loading-videos {
    text-align: center;
    padding: 20px;
    color: #606060;
    font-family: Lato;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loading-spinner::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border: 3px solid #6DD3CE;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .wrapped-content {
        padding: 20px;
        padding-top: 60px;
    }

    .wrapped-content h1 {
        font-size: 32px;
        margin-top: 20px;
    }

    .description {
        font-size: 16px;
        padding: 0 20px;
    }

    .wrapped-button {
        padding: 12px 24px;
        font-size: 16px;
    }

    .stat-group {
        gap: 20px;
    }

    .wrapped-stat-item {
        gap: 10px;
    }

    .wrapped-stat-item h2 {
        font-size: 14px;
    }

    .wrapped-stat-item .stat-number {
        font-size: 20px;
    }

    .wrapped-channel-item {
        padding: 10px;
        gap: 10px;
    }

    .channel-rank {
        font-size: 16px;
        min-width: 25px;
    }

    .video-thumbnail {
        width: 90px;
        height: 51px;
        margin-right: 10px;
    }

    .list-video-title {
        font-size: 12px;
    }

    .list-video-date {
        font-size: 11px;
    }

    .channel-videos {
        padding: 10px;
    }
}

.wrapped-share-button {
    margin-bottom: 20px;
}

.wrapped-shareable {
    width: 1080px;
    height: 1920px;
    background-color: #eebbbb;
    position: absolute;
    left: -9999px;
    padding: 80px 60px;
}

.wrapped-share-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
}

.wrapped-share-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
        linear-gradient(45deg, #dddddd 25%, transparent 25%),
        linear-gradient(-45deg, #dddddd 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #dddddd 75%),
        linear-gradient(-45deg, transparent 75%, #dddddd 75%);
    background-size: 40px 40px;
    background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
    opacity: 0.1;
    z-index: -1;
}

/* Profile section */
.wrapped-share-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
}

.wrapped-share-avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
}

.wrapped-share-profile h2 {
    font-family: Poppins;
    font-size: 72px;
    font-weight: 600;
    color: #333;
    margin: 0;
    line-height: 1.2;
}

/* Stats section */
.wrapped-share-stats {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;
}

.wrapped-share-stat {
    text-align: center;
}

.share-number {
    font-family: Poppins;
    font-size: 96px;
    font-weight: 600;
    color: #111111;
    display: block;
}

.share-label {
    font-family: Lato;
    font-size: 32px;
    color: #666;
    margin-top: 10px;
}

/* Channels section */
.wrapped-share-channels {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.wrapped-share-channels h3 {
    font-family: Poppins;
    font-size: 48px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.wrapped-share-channel {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 25px;
    background: white;
    border-radius: 12px;
}

.share-rank {
    font-family: Poppins;
    font-weight: 600;
    /* color: #6DD3CE; */
    color: black;
    font-size: 36px;
    min-width: 60px;
}

.share-channel-name {
    flex: 1;
    font-family: Lato;
    font-size: 32px;
    color: #333;
}

.share-video-count {
    font-family: Lato;
    font-size: 28px;
    color: #666;
}

/* Footer */
.wrapped-share-footer {
    text-align: center;
    font-family: Lato;
    font-size: 32px;
    color: #666;
    padding-top: 40px;
    margin-top: auto;
}

/* Profile section enhancements */
.wrapped-share-avatar-container {
    position: relative;
    margin-bottom: 10px;
}

.wrapped-share-avatar {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 6px solid white;
    /* Added white border */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.wrapped-share-youtube-icon {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
}

.wrapped-share-youtube-icon svg {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 480px) {
    .stat-group {
      flex-direction: column;
      gap: 20px;
    }
  
    .stat-divider {
      display: none;  /* Hide the divider when stacked */
    }
  }

.auth-note {
    font-size: 0.9em;
    color: #666;
    margin-top: 12px;
    text-align: center;
    font-family: Lato;
}

.wrapped-input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.feedback-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 20px;
    width: 300px;
    height: 40px;
    max-width: 100%;
    margin-right: 0px;
    font-family: Lato;
}

.error-message {
    text-align: center;
    /* color: #d32f2f; */
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    background-color: #eebbbb8d;
    font-family: Lato;
}

.error-message p {
    margin-bottom: 15px;
}