footer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #eeeeee;
  width: 100vw;
}

footer a {
  display: inline-block;
}

footer img {
  width: 30px; /* Example size, adjust as needed */
  margin-left: 20px;
  margin-right: 20px;
  height: auto;
}