.analysisResult {
  margin-left: 300px;
  margin-right: 300px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-family: Lato;
  font-size: 20px;

}

.vidText {
  display: flex;
}

.vidRating {
  font-size: 80px;
  margin-right: 50px;
  font-family: Kalam;
}

.vidSumm {
  text-align: left;
}