/* TopBar.css */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #eebbbb; /* Adjust background color as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  height: 60px;
  font-family: 'Lato';
  position: fixed;
  top: 0;
  left: 0;
}

.top-bar-title {
  font-size: 42px;
  font-weight: bold;
  margin-left: 100px;
  font-family: 'Poppins';
}

.top-bar-title:hover {
  cursor: pointer;
  text-shadow: 1px 1px 1px white;
}

.top-bar-profile img {
  height: 50px; /* Adjust size as needed */
  width: 50px;
  border-radius: 50%; /* Makes the image circular */
  margin-right: 50px;
  margin-left: 30px;
}

.dropdown-menu {
  position: absolute;
  right: 60px; /* Adjust as needed */
  top: 75px; /* Adjust based on the size of the top bar */
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Lato';

}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 5px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Other styles */
}

.navigation-links button {
  margin-right: 10px; /* Adjust spacing between buttons */
  background-color: transparent;
  border: none;
  color: inherit; /* Inherits the color from .top-bar */
  cursor: pointer;
  font-size: 16px;
}

.navigation-links button:hover {
  text-decoration: underline;
}

.right-side {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px;
  background-color: #eebbbb; /* Adjust background color as needed */
  height: 60px;
}

.log-in-button {
  margin-right: 30px;
}

@media (max-width: 850px) {
  .top-bar {
    justify-content: space-around; /* Adjust content spacing for smaller screens */
    padding: 5px; /* Reduce padding */
    height: 50px; /* Adjust height for a more compact view */
  }

  .top-bar-title {
    font-size: 30px; /* Reduce font size for smaller screens */
    margin-left: 10px; /* Reduce margin */
  }

  .top-bar-profile img {
    height: 40px; /* Adjust image size */
    width: 40px;
    margin-right: 10px; /* Adjust margin */
    margin-left: 5px;
  }

  .dropdown-menu {
    /* right: 10px; Adjust position closer to the right edge */
    top: 55px; /* Adjust top position to align under the top bar */
  }

  .navigation-links button {
    font-size: 14px; /* Reduce font size for link buttons */
    padding: 5px; /* Adjust padding */
  }

  .right-side {
    height: 30px;
  }
}

@media (max-width: 550px) {
  .top-bar {
    justify-content: space-around; /* Adjust content spacing for smaller screens */
    padding: 5px; /* Reduce padding */
    height: 50px; /* Adjust height for a more compact view */
  }

  .top-bar-title {
    font-size: 15px; /* Reduce font size for smaller screens */
    margin-left: 10px; /* Reduce margin */
    /* content: "S-TIER"; */
  }

  .top-bar-profile img {
    height: 40px; /* Adjust image size */
    width: 40px;
    margin-right: 10px; /* Adjust margin */
    margin-left: 5px;
  }

  .dropdown-menu {
    right: 10px;
    top: 55px; /* Adjust top position to align under the top bar */
  }

  .navigation-links button {
    font-size: 12px; /* Reduce font size for link buttons */
    padding: 2px; /* Adjust padding */
  }

  .right-side {
    height: 30px;
  }

  .log-in-button {
    font-size: 12px;
    margin-right: 10px;
  }
}
