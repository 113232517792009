.review {
  display: flex;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc; /* Optional */
  background-color: #dddddd;
}

.review-rating {
  font-size: 72px; /* Big size for rating */
  margin-right: 50px; /* Space between rating and content */
  margin-left: 35px;
}

.review-content {
  margin: 5px 0; /* Spacing between paragraphs */
  text-align: left;
}

.review-text {
  margin-top: 10px;
  font-family: Lato;
}

.review-description {
  font-size: 16px;
  color: gray;
  font-family: Lato; /* is this good? */
}

@media (max-width: 768px) {
  .review {
    /* flex-direction: column; Stack elements vertically on smaller screens */
    padding: 5px; /* Reduce padding */
  }

  .reviews-section {
    margin-left: 20px;
    margin-right: 20px;
  }

  .review-rating {
    font-size: 32px; /* Smaller font size for the rating */
    margin-right: 20px; /* Reduce the space to the right */
    margin-left: 10px; /* Reduce the space to the left */
    align-self: center; /* Center the rating within its container */
  }

  .review-content {
    margin: 5px 0; /* Maintain vertical spacing */
    /* text-align: center; Center text for better readability on narrow screens */
  }

  .review-text {
    font-size: 14px; /* Smaller font size for the text */
  }

  .review-description {
    font-size: 16px; /* Slightly smaller font size for the description */
  }
}
