.topic-list {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  align-items: center;
  margin-top: 100px;
  font-family: Lato;
  font-size: 20px;
}

.topic-list h1 {
  margin-bottom: 50px;
  font-family: 'Poppins';
  font-weight: 600;
}

.topic-list-grid {
  width: 400px;
}

.topic-list-item {
  margin-bottom: 10px;
  text-align: left;
  padding: 20px;
  display: flex;
  align-items: center;
}

.topic-list-item:hover {
  background-color: #dddddd;
}

.topic-icon {
  margin-right: 15px;  /* Adjust spacing between the icon and the text */
  width: 50px;        /* Adjust size as needed */
  height: 50px;       /* Adjust size as needed */
}

.topic-list-title {
  align-items: center;
  vertical-align: middle;
  height: 100%;
}

@media (max-width: 768px) {
  .topic-list {
    padding: 0px; /* Smaller padding for smaller screens */
    margin-top: 60px; /* Reduced top margin to account for a smaller fixed top bar */
  }

  .topic-list h1 {
    margin-bottom: 20px; /* Smaller bottom margin for the heading */
  }

  .topic-list-grid {
    width: 90%; /* Grid takes full width to utilize space efficiently */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust the column size for smaller devices */
  }

  .topic-list-item {
    gap: 10px; /* Smaller gaps between items */
    padding: 5px; /* Reduced item padding */
  }

  .topic-list-title {
    font-size: 18px; /* Smaller font size for text */
  }

  .topic-icon {
    width: 30px;
    height: 30px;
  }
}