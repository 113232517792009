/* Container for the channel content below the top bar */
.channel-content {
  display: flex;
  justify-content: space-between;
  margin-left: 60px;
  margin-right: 60px;
  /* margin-top: 20px;
  padding: 20px; */
}

.channelpage {
  width: 100vw;
  min-height: 100vh;
  max-width: 1600px;
}

.channel-div-container {
  width: 100%;
}

.channel-all {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 150px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
}

/* Left column for channel details */
.channel-details {
  flex: 1; /* Take up half of the container */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the flex-direction */
  max-width: 70%;
}

.channel-details .channel-name {
  font-size: 42px; /* Big text for channel name */
  font-weight: 600;
  margin-right: 50px;
  text-align: left;
  max-width: 500px;
  font-family: Poppins;
  word-wrap: break-word;
}

.review-channel-description {
  font-family: Poppins;
  font-weight: 400;
}

.channel-details .channel-subtitle {
  font-size: 18px; /* Subtitle text size */
  color: #666; /* Subtle color for subtitle */
  margin-bottom: 20px; /* Space between subtitle and profile picture */
  font-family: Lato;
  font-style: italic;
}

.channel-details .profile-and-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.channel-details img {
  width: 100px; /* Adjust based on your preference */
  height: 100px; /* Adjust to keep aspect ratio */
  border-radius: 50%; /* Circle shape */
  margin-right: 20px; /* Space between picture and description */
  margin-bottom: 20px;
  margin-top: 10px;
}

.channel-details .channel-description {
  flex: 1;
  font-size: 15px;
  font-family: Lato;
  text-align: left;
}

/* Right column for stats */
.channel-stats {
  flex: 1; /* Take up half of the container */
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 70px;
  font-family: Lato;
  max-width: 40%;
}

.stat-item {
  margin-bottom: 10px; /* Space between stat items */
}

/* Adjustments for the review section if needed */
.reviews-section {
  /* margin-top: 40px; */
  margin-left: 90px;
  margin-right: 90px;
  /* max-width: 1500px; */
}


.review-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.review-popup select, .review-popup textarea, .review-popup button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: Lato;
}

.review-popup textarea {
  height: 100px;
  resize: vertical; /* Allow vertical resizing */
}

.review-popup button {
  /* background-color: #4285f4; */
  color: white;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.review-popup button:hover {
  background-color: #357ae8;
}

.rating-buttons {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  width: 100%;
}

.rating-button {
  padding: 5px 10px;
  margin: 100px;
  border: 1px solid #ddd;
  background-color: lightgray;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 100%;
}

.rating-button:hover {
  background-color: #f0f0f0;
}

.rating-button.selected {
  background-color: #4285f4;
  color: white;
  transform: scale(1.1);
}

.first-review {
  font-family: Lato;
  font-style: italic;
}

textarea {
  font-family: Lato; /* Example: Arial, you can use any font you prefer */
  font-size: 1rem; /* Adjust the font size as needed */
  /* ... other existing styles ... */
}

.review {
  cursor: pointer;
  /* Other styles like padding, margin, hover effects, etc. */
}

.review:hover {
  background-color: #cccccc; /* Example hover effect */
}

.channel-info {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
}

.channel-info img {
  margin-top: 10px;
  margin-bottom: 10px;
}

.statistics {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.reviews-list {
  list-style-type: none;
  padding: 0;
}

.review {
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.ratings-distribution {
  max-width: 300px;
}

.homepage {
  background-color: eeeeee;
}

.newChannel button {
  font-size: 24px;
  padding: 20px;
  margin: 20px;
}

.youtubeImg img {
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .channel-all {
    padding-top: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .channel-details .channel-name {
    font-size: 24px; /* Smaller text for smaller screens */
    margin-right: 20px; /* Reduced margin */
  }

  .channel-details .channel-subtitle {
    font-size: 14px; /* Smaller font size for subtitle */
  }

  .channel-details img {
    width: 35px; /* Smaller image size */
    height: 35px; /* Maintain aspect ratio */
    margin-right: 10px;
  }

  .channel-details .profile-and-name {
    /* flex-direction: column; Stack items vertically */
    align-items: center; /* Center align items */
  }

  .channel-details .description {
    font-size: 14px; /* Smaller font size for description */
  }

  .channel-details {
    max-width: 100%;
  }

  .channel-stats {
    margin-left: 0px; /* Adjusted margin */
    margin-top: 20px;
    max-width: 100%;
    /* align-items: center; Center align items */
  }

  .stat-item {
    font-size: 14px; /* Smaller text for stats */
  }

  .reviews-section {
    margin-left: 20px;
    margin-right: 20px;
  }

  .channel-content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }

  .profile-and-name {
    max-width: 100%;
  }

  .ratings-distibution {
    align-items: left;
  }
}
