.videoList {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  align-items: center;
  margin-top: 100px;
}

.videoList h1 {
  margin-bottom: 50px;
}

.video-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 5px;
  width: 650px;
  /* padding: 20px 0; */
}

.video-list-item {
  display: flex;
  align-items: center;
  gap: 30px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.2s;
  font-family: Lato;
  text-align: left;
}

.video-list-item:hover {
  background-color: #dddddd;
}

.video-list-item img {
  width: 120px;
  height: 90px;
  object-fit: cover;
}

.video-list-item span {
  font-size: 16px;
}

@media (max-width: 768px) {
  .videoList {
    padding: 0px; /* Smaller padding for smaller screens */
    margin-top: 60px; /* Reduced top margin to account for a smaller fixed top bar */
  }

  .videoList h1 {
    margin-bottom: 20px; /* Smaller bottom margin for the heading */
  }

  .video-list-grid {
    width: 90%; /* Grid takes full width to utilize space efficiently */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust the column size for smaller devices */
  }

  .video-list-item {
    gap: 10px; /* Smaller gaps between items */
    padding: 5px; /* Reduced item padding */
  }

  .video-list-item img {
    width: 50px; /* Smaller images */
    height: 50px;
  }

  .video-list-item span {
    font-size: 14px; /* Smaller font size for text */
  }
}
