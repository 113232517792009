/* Homepage.css */

.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eeeeee;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 100%;
  padding-top: 100px;
  margin-top: 100px;
  /* font-size: 20px; */
}

.welcome {
  font-size: 35px;
  padding-top: 30px;
  font-family: Poppins;
  font-weight: 500;
}

h2 {
  font-family: Poppins;
  font-weight: 600;
}

.searchbar {
  font-family: 'Lato', sans-serif;
  margin-top: 40px;
  margin-bottom: 40px;
}

.top-bar {
  flex-shrink: 0; /* Prevents the top bar from shrinking */
  width: 100%; /* Ensures the top bar spans the full width */
  position: fixed; /* Fixes the top bar at the top */
  top: 0; /* Aligns the top bar at the top of the page */
  z-index: 1000; /* Ensures the top bar stays above other content */
}

/* You may want to add some padding to the top of the content area 
   so it doesn't get hidden behind the fixed top bar */
.content-area {
  margin-top: 60px; /* Adjust this value based on the height of your top bar */
  flex-grow: 1; /* Allows the content area to fill the remaining space */
  overflow-y: auto; /* Adds scroll to the content area if needed */
}

/* Search bar form */

form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.searchbar input[type="text"] {
  padding: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 24px;
  font-family: Lato;
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #ccbbaa;
  /* color: white; */
  cursor: pointer;
  font-family: Lato;
}

button:hover {
  background-color: #ccccaa;
}

.channel-list {
  display: flex;
  justify-content: center;
}

.video-list {
  display: flex;
  justify-content: center;
}

.channel-item {
  margin: 5px;
  padding: 20px;
  font-family: Lato;
}

.video-item {
  margin: 5px;
  padding: 10px;
  font-family: Lato;
  width: 200px;
  height: 200px;
}

.channel-item img {
  width: 100px;
  height: 100px;
  border-radius: 30%;
}

.channel-item:hover {
  background-color: #dddddd;
}

.video-item:hover {
  background-color: #dddddd;
}

.home-all {
  background-color: #eeeeee;
}

.popular-videos {
  align-items: center;
  justify-content: center;
}

.video-list {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.video-title {
  font-size: 20px;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .channel-item img {
      width: 50px; /* Reduces image width on mobile */
      height: auto; /* Maintains aspect ratio */
  }

  .searchbar {
    margin-left: 40px;
    margin-right: 40px;
  }

  .channel-item {
    padding: 5px;
    font-size: 14px;
  }

  /* Here you can add more mobile-specific style adjustments */
  .searchbar input[type="text"] {
      font-size: 16px; /* Reduces font size in the search bar on mobile */
      padding: 10px; /* Reduces padding for a smaller form factor */
  }

  .welcome {
      font-size: 24px; /* Reduces the font size of the welcome message */
  }

  .button {
      padding: 8px; /* Adjusts padding for buttons */
  }

  .video-list {
    flex-direction: column;
  }

  .video-item {
    display: flex;
    height: 80px;
    width: 70%;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .video-item p {
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
  }

  .video-thumbnail {
    height: 60px;
    width: 80px;
    align-self: center;
  }
}
